<template>
  <v-container fluid>
    <div class="derecha">
      <v-btn color="success" outlined x-small @click="dialogNuevo = true">
        Nuevo Cliente
      </v-btn>
    </div>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="listadoCliente"
      :custom-filter="filterOnlyCapsText"
      :items-per-page="5"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Buscar:"
          class="mx-4 ext-uppercase"
        >
        </v-text-field>
      </template>
      <template v-slot:item="row">
        <tr @dblclick="guardarCliente(row.item.id)">
          <td>{{ row.item.documentlong }}</td>
          <td>{{ row.item.namelong }}</td>
          <td>{{ row.item.phone }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogNuevo" max-width="600px">
      <v-card class="pa-5">
        <registrarCliente />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import funcion from "@/mixins/funciones";

export default {
  mixins: [funcion],
  name: "buscarClienteComponent",
  components: {
    registrarCliente: () =>
      import("../../components/comun/registrarClienteComponet.vue"),
  },
  data() {
    return {
      dialogNuevo: false,
      search: "",
      headers: [
        { value: "documentlong", text: "Documento", align: "center" },
        { value: "namelong", text: "Nombres", align: "center" },
        { value: "phone", text: "Teléfono", align: "center" },
      ],
    };
  },
  mounted() {},
  created() {},
  methods: {
    ...mapMutations(["setusuarioSeleccionadoFlag"]),
    filterOnlyCapsText(value, search) {
      let text = search ? search.toLocaleUpperCase() : null;
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(text) !== -1
      );
    },
    guardarCliente(idSeleccion) {
      let cliente = this.listadoCliente.filter((v) => v.id == idSeleccion);
      let data = {
        id: idSeleccion,
        name: cliente[0].namelong,
        phone: cliente[0].phone,
      };
      this.setusuarioSeleccionadoFlag(data);
    },
  },
  computed: {
    ...mapState(["listadoCliente", "nuevoUsuarioFlag", "datosPrincipales"]),
  },
  watch: {
    nuevoUsuarioFlag() {
      this.dialogNuevo = false;
    },
  },
};
</script>

<style>
.derecha {
  text-align: right;
}
</style>
